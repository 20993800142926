<!-- VideoPlayer.vue -->
<template>
  <div class="video-player">
    <label for="comboCanais">Selecione o canal: </label>  
    <select v-model="canalSelecionado" id="comboCanais" @change="iniciaDoZero">
      <option v-for="listaCanais in PastaVideos"  :key="listaCanais.value"> 
        {{listaCanais}}
      </option>
    </select>
<!--    <p></p>
    Canal selecionado: {{canalSelecionado}}  -->
    <p></p>
    <button @click="iniciarFila">Muda para tela cheia / Full screen </button>
    <p></p>
    Video exibido: {{ videos[this.currentVideoIndex] }}
    <p></p>    
    Video {{ this.currentVideoIndex + 1 }} de {{ qVideos }}
    <p></p>    
    <video 
      ncontextmenu="false"
      disablePictureInPicture 
      controlsList="nodownload" 
      ref="videoPlayer"
      :src="currentVideo"
      @ended="playNextVideo"
      class="video"
      autoplay>
    </video>
    <p></p>        
    <button @click="playNextVideo">Avança para o proximo video</button>
  </div>

</template>

  
  <script>
  import axios from 'axios';
  export default {
    data() {
      return {
        canalSelecionado: "Canal Loja",
        user: null,
        browserName: "",
        videos: [],
        PastaVideos: [], 
        localVideos: "Canal Loja",
        currentVideoIndex: 0,
        qVideos: 0,
        url_api_pastas: 'https://api-tv.grupo-behnck.com.br/pastas',
        url_api_arquivos: 'https://api-tv.grupo-behnck.com.br/arquivos/',
        url_api_pastas2: 'http://localhost:8010/pastas',
        url_api_arquivos2: 'http://localhost:8010/arquivos/'
      };
    },
    computed: {
      currentVideo() {
      return "/tv-lojas/"+this.canalSelecionado+"/"+this.videos[this.currentVideoIndex];
//        return "/tv-lojas/"+this.videos[this.currentVideoIndex];
      }
    },
    created() { 
      this.carregaNomePastas(),
      this.carregaNomeVideos()},
    mounted() {
      this.$nextTick(() => {this.enterFullscreen();});
              },
    methods: {    
      enterFullscreen() {
        const videoContainer = this.$refs.videoPlayer;
        if (videoContainer.requestFullscreen) {
          videoContainer.requestFullscreen();} 
        else if (videoContainer.mozRequestFullScreen) { // Firefox
           videoContainer.mozRequestFullScreen();} 
        else if (videoContainer.webkitRequestFullscreen) { // Chrome, Safari and Opera
        videoContainer.webkitRequestFullscreen();} 
        else if (videoContainer.msRequestFullscreen) { // IE/Edge
        videoContainer.msRequestFullscreen(); }
       },
      iniciaDoZero() {
        localStorage.setItem("LojaSalva", this.canalSelecionado);
        this.videos = [];
        this.currentVideo = 0;
        this.currentVideoIndex = 0;
        this.qVideos = 0;
        this.carregaNomeVideos()
      },
      carregaNomePastas() {
        axios.get(this.url_api_pastas)
        .then(response => {this.PastaVideos = response.data})
        .catch(error => {console.error('Erro ao buscar pasta de arquivos:', error)})},
      carregaNomeVideos() {
        const savedOption = localStorage.getItem("LojaSalva");
        if (savedOption) {
          this.canalSelecionado = savedOption;};
        this.localVideos = this.canalSelecionado;
        axios.put(this.url_api_arquivos+this.localVideos)
        .then(response => {this.videos = response.data,this.qVideos = this.videos.length})
        .catch(error => {console.error('Erro ao buscar pasta de arquivos:', error)})
              ;
        },
      iniciarFila() {
        this.carregaNomeVideos();
        this.$refs.videoPlayer.load();
        this.$refs.videoPlayer.requestFullscreen();
      },
      playNextVideo() {
        if (this.currentVideoIndex < this.videos.length - 1) {
          this.currentVideoIndex++;
        } else {
          this.currentVideoIndex = 0;
          this.carregaNomeVideos();
        }
          this.$refs.videoPlayer.load();
      },

        
    }
  };

</script>
  
<style>
  .video-player {
    text-align: center;
    margin: 20px;}
  .video {
    width: 100%;
    max-width: 600px;
    height: 100%;}
  
  .controls {
    margin-top: 0px;
    text-align:center;
  }
</style>
  