<template>
        <div id="app">
            <VideoPlayer />
<!--            <nav>
                <router-link to="/">Home</router-link>
                <router-link to="/about">About</router-link>
            </nav>
            <router-view /> -->
        </div>
</template>

<script>
    import VideoPlayer from "./components/VideoPlayer.vue"
    export default { 
          components: { 
              VideoPlayer }}
</script>

<style>
    #app {
        margin: 0;
        padding: 0;
        background-color:  rgb(148, 205, 228) ;} 
</style>